import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Puzzle from "./Components/Puzzle";
import Home from "./Components/Home";
import Redeem from "./Components/Redeem";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="game" element={<Puzzle />}></Route>
          <Route path="/redeem/:uniqueId" element={<Redeem />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
