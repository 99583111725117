import { useNavigate } from "react-router-dom";
import okDadaLogo from "../assets/ok_dada_logo.png";
import poweredByWorkplay from "../assets/workplay.png";
import "../Styles/Home.css";
import { useEffect, useState } from "react";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [showHome, setShowHome] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowHome(true);
    }, 100);
    sessionStorage.clear();
  });

  const handlePlay = () => {
    navigate("/game");
  };

  const handleLogoRedirect = () => {
    window.open("https://www.workplay.digital/", "_blank");
  };

  return (
    <section id="home">
      <div className={`home-container ${showHome ? "visible" : ""}`}>
        <img
          className="mx-auto flex h-[120px] justify-center max-sm:h-[100px]"
          src={okDadaLogo}
          alt="ok dada"
        />
        <p className="mt-15 m-auto w-[450px] font-geomanistRegular text-xl font-extrabold text-[#fee1fa] drop-shadow-text max-sm:my-4 max-sm:w-[290px] max-sm:text-base max-[330px]:w-[250px]">
          Complete the puzzle and Win a Free Icecream for your friend, so you
          don't need to resist to bring your friend.
        </p>
        <button
          className="my-20 rounded-full border-2 border-black bg-[#fe92d2] px-10 py-3 font-geomanistRegular text-xl font-extrabold uppercase text-black shadow-btn transition-shadow hover:shadow-hoverBtn max-sm:mt-5 max-sm:px-8 max-sm:py-2 max-sm:text-xl"
          onClick={handlePlay}
        >
          Play
        </button>
        <div className="cursor-pointer" onClick={handleLogoRedirect}>
          <p className="mt-24 text-base text-white drop-shadow-text max-sm:mt-32 max-sm:text-sm">
            powered by
          </p>
          <img
            className="m-auto flex  h-10 justify-center drop-shadow-btn max-sm:h-8"
            src={poweredByWorkplay}
            alt="workplay logo"
          />
        </div>
      </div>
    </section>
  );
};

export default Home;
