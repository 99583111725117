import powered from "../assets/workplay.png";
import { FormEvent, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import OKDada from "../assets/ok_dada_logo.png";
const { REACT_APP_LOGO_URL, REACT_APP_API } = process.env;

interface Props {
  seconds: number;
  minutes: number;
  remainingSeconds: number;
}

interface user {
  email: string;
  name: string;
}

function Result({ seconds, minutes, remainingSeconds }: Props) {
  const navigate = useNavigate();
  const TimeOut = seconds >= 179;
  const [second, setSecond] = useState(0);
  const [minute, setMinute] = useState(0);
  const [user, setUser] = useState({ id: uuidv4(), email: "", name: "" });

  useEffect(() => {
    setSecond(seconds);
    setMinute(minutes);
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    sessionStorage.setItem("username", user.name);
    sessionStorage.setItem("email", user.email);
    sessionStorage.setItem("uniqueId", user.id);
    const loadingToast = toast.loading("Submitting...");
    try {
      const response = await axios.get(
        `${REACT_APP_API}/check-email?email=${user.email}`,
      );

      if (response.data.exists) {
        toast.error("You have already won a coupon!");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        const postResponse = await axios.post(
          `${REACT_APP_API}/puzzle-result`,
          {
            name: user.name,
            email: user.email,
            uniqueId: user.id,
            timestamp: new Date().toISOString(),
          },
        );

        if (postResponse.status === 201) {
          const emailMessage = `<!DOCTYPE html>
          <html
            lang="en"
            xmlns="http://www.w3.org/1999/xhtml"
            xmlns:v="urn:schemas-microsoft-com:vml"
            xmlns:o="urn:schemas-microsoft-com:office:office"
          >
            <head>
              <meta charset="utf-8" />
              <!-- utf-8 works for most cases -->
              <meta name="viewport" content="width=device-width" />
              <!-- Forcing initial-scale shouldn't be necessary -->
              <meta http-equiv="X-UA-Compatible" content="IE=edge" />
              <!-- Use the latest (edge) version of IE rendering engine -->
              <meta name="x-apple-disable-message-reformatting" />
              <!-- Disable auto-scale in iOS 10 Mail entirely -->
              <title></title>
              <!-- The title tag shows in email notifications, like Android 4.4. -->
          
              <link
                href="https://fonts.googleapis.com/css?family=Work+Sans:200,300,400,500,600,700"
                rel="stylesheet"
              />
          
              <!-- CSS Reset : BEGIN -->
              <style>
                html,
                body {
                  margin: 0 auto !important;
                  padding: 0 !important;
                  height: 100% !important;
                  width: 100% !important;
                  background: #f1f1f1;
                }
          
                /* What it does: Stops email clients resizing small text. */
                * {
                  -ms-text-size-adjust: 100%;
                  -webkit-text-size-adjust: 100%;
                }
          
                /* What it does: Centers email on Android 4.4 */
                div[style*="margin: 16px 0"] {
                  margin: 0 !important;
                }
          
                /* What it does: Stops Outlook from adding extra spacing to tables. */
                table,
                td {
                  mso-table-lspace: 0pt !important;
                  mso-table-rspace: 0pt !important;
                }
          
                /* What it does: Fixes webkit padding issue. */
                table {
                  border-spacing: 0 !important;
                  border-collapse: collapse !important;
                  table-layout: fixed !important;
                  margin: 0 auto !important;
                }
          
                /* What it does: Uses a better rendering method when resizing images in IE. */
                img {
                  -ms-interpolation-mode: bicubic;
                }
          
                /* What it does: Prevents Windows 10 Mail from underlining links despite inline CSS. Styles for underlined links should be inline. */
                a {
                  text-decoration: none;
                }
          
                /* What it does: A work-around for email clients meddling in triggered links. */
                *[x-apple-data-detectors],  /* iOS */
          .unstyle-auto-detected-links *,
          .aBn {
                  border-bottom: 0 !important;
                  cursor: default !important;
                  color: inherit !important;
                  text-decoration: none !important;
                  font-size: inherit !important;
                  font-family: inherit !important;
                  font-weight: inherit !important;
                  line-height: inherit !important;
                }
          
                /* What it does: Prevents Gmail from displaying a download button on large, non-linked images. */
                .a6S {
                  display: none !important;
                  opacity: 0.01 !important;
                }
          
                /* What it does: Prevents Gmail from changing the text color in conversation threads. */
                .im {
                  color: inherit !important;
                }
          
                /* If the above doesn't work, add a .g-img class to any image in question. */
                img.g-img + div {
                  display: none !important;
                }
          
                /* What it does: Removes right gutter in Gmail iOS app: https://github.com/TedGoas/Cerberus/issues/89  */
                /* Create one of these media queries for each additional viewport size you'd like to fix */
          
                /* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
                @media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
                  u ~ div .email-container {
                    min-width: 320px !important;
                  }
                }
                /* iPhone 6, 6S, 7, 8, and X */
                @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
                  u ~ div .email-container {
                    min-width: 375px !important;
                  }
                }
                /* iPhone 6+, 7+, and 8+ */
                @media only screen and (min-device-width: 414px) {
                  u ~ div .email-container {
                    min-width: 414px !important;
                  }
                }
              </style>
          
              <!-- CSS Reset : END -->
          
              <!-- Progressive Enhancements : BEGIN -->
              <style>
          
                      .primary{
                  background: #2f89fc;
                }
                .bg_white{
                  background: #ffffff;
                }
                .bg_light{
                  background: #fafafa;
                }
                .bg_black{
                  background: #000000;
                }
                .bg_dark{
                  background: rgba(0,0,0,.8);
                }
                .email-section{
                  padding: 1em 2.5em 2em 2.5em;
                }
          
                /*BUTTON*/
                .btn{
                  padding: 5px 15px;
                  display: inline-block;
                }
                .btn.btn-primary{
                  border-radius: 5px;
                  background: #2f89fc;
                  color: #ffffff;
                }
                .btn.btn-white{
                  border-radius: 5px;
                  background: #ffffff;
                  color: #000000;
                }
                .btn.btn-white-outline{
                  border-radius: 5px;
                  background: transparent;
                  border: 1px solid #fff;
                  color: #fff;
                }
          
                h1,h2,h3,h4,h5,h6{
                  font-family: 'Work Sans', sans-serif;
                  color: #000000;
                  margin-top: 0;
                  font-weight: 400;
                }
          
                body{
                  font-family: 'Work Sans', sans-serif;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 1.8;
                  color: rgba(0,0,0,.4);
                }
          
                a{
                  color: #2f89fc;
                }
          
                table{
                }
                /*LOGO*/
          
                .logo h1{
                  margin: 0;
                }
                .logo h1 a{
                  color: #000000;
                  font-size: 20px;
                  font-weight: 700;
                  text-transform: uppercase;
                  font-family: 'Poppins', sans-serif;
                }
          
                .navigation{
                  padding: 0;
                }
                .navigation li{
                  list-style: none;
                  display: inline-block;;
                  margin-left: 5px;
                  font-size: 13px;
                  font-weight: 500;
                }
                .navigation li a{
                  color: rgba(0,0,0,.4);
                }
          
                /*HERO*/
                .hero{
                  position: relative;
                  z-index: 0;
                }
          
                .hero .text{
                  color: rgba(0,0,0,.3);
                }
                .hero .text h2{
                  color: #000;
                  font-size: 30px;
                  margin-bottom: 0;
                  font-weight: 300;
                }
                .hero .text h2 span{
                  font-weight: 600;
                  color: #2f89fc;
                }
          
          
                /*HEADING SECTION*/
                .heading-section{
                }
                .heading-section h2{
                  color: #000000;
                  font-size: 28px;
                  margin-top: 0;
                  line-height: 1.4;
                  font-weight: 400;
                }
                .heading-section .subheading{
                  margin-bottom: 20px !important;
                  display: inline-block;
                  font-size: 13px;
                  text-transform: uppercase;
                  letter-spacing: 2px;
                  color: rgba(0,0,0,.4);
                  position: relative;
                }
                .heading-section .subheading::after{
                  position: absolute;
                  left: 0;
                  right: 0;
                  bottom: -10px;
                  content: '';
                  width: 100%;
                  height: 2px;
                  background: #2f89fc;
                  margin: 0 auto;
                }
          
                .heading-section-white{
                  color: rgba(255,255,255,.8);
                }
                .heading-section-white h2{
                  font-family:
                  line-height: 1;
                  padding-bottom: 0;
                }
                .heading-section-white h2{
                  color: #ffffff;
                }
                .heading-section-white .subheading{
                  margin-bottom: 0;
                  display: inline-block;
                  font-size: 13px;
                  text-transform: uppercase;
                  letter-spacing: 2px;
                  color: rgba(255,255,255,.4);
                }
          
                /*PROJECT*/
                .text-project{
                  padding-top: 10px;
                }
                .text-project h3{
                  margin-bottom: 0;
                }
                .text-project h3 a{
                  color: #000;
                }
          
                /*FOOTER*/
          
                .footer{
                  color: rgba(255,255,255,.5);
          
                }
                .footer .heading{
                  color: #ffffff;
                  font-size: 20px;
                }
                .footer ul{
                  margin: 0;
                  padding: 0;
                }
                .footer ul li{
                  list-style: none;
                  margin-bottom: 10px;
                }
                .footer ul li a{
                  color: rgba(255,255,255,1);
                }
          
          
                @media screen and (max-width: 500px) {
          
          
                }
              </style>
            </head>
            <body
              width="100%"
              style="
                margin: 0;
                padding: 0 !important;
                mso-line-height-rule: exactly;
                background-color: #222222;
              "
            >
              <center style="width: 100%; background-color: #f1f1f1">
                <div
                  style="
                    display: none;
                    font-size: 1px;
                    max-height: 0px;
                    max-width: 0px;
                    opacity: 0;
                    overflow: hidden;
                    mso-hide: all;
                    font-family: sans-serif;
                  "
                >
                  &zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
                </div>
                <div style="max-width: 600px; margin: 0 auto" class="email-container">
                  <table align="center" role="presentation" cellspacing="0"
                  cellpadding="0" border="0" width="100%" style="margin: auto;" ">
                  <tr>
                    <td
                      valign="top"
                      class="bg_white"
                      style="
                        padding: 1em 2.5em;
                        width: 100%;
                        height: 100px;
                        background: #7289da
                          url(https://s3.ap-south-1.amazonaws.com/cdn.workplay.digital/assets/frame.png)
                          top center / cover no-repeat;
                      "
                    >
                      <table
                        role="presentation"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                      >
                        <tr>
                          <td class="logo" style="text-align: center">
                            <img
                              style="height: 150px"
                              src="https://s3.ap-south-1.amazonaws.com/cdn.workplay.digital/assets/ok_dada_logo.png"
                              alt="ok dada logo"
                            />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td class="bg_white">
                      <table
                        role="presentation"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                        width="100%"
                      >
                        <tr>
                          <td
                            class="bg_white email-section"
                            style="
                              padding: 1em 3em 2em 3em;
                              background: #7289da
                                url(https://s3.ap-south-1.amazonaws.com/cdn.workplay.digital/assets/backgroundEmail.png)
                                top center / cover no-repeat;
                            "
                          >
                            <div
                              class="heading-section"
                              style="text-align: left; padding: 0 10px"
                            >
                              <h2
                                style="
                                  font-family: Poppins, sans-serif;
                                  font-weight: 700;
                                  font-size: 22px;
                                  color: white;
                                  letter-spacing: 0.5px;
                                "
                              >
                                Hey ${user.name},
                              </h2>
                              <p
                                style="
                                  font-size: 15px;
                                  font-weight: 400;
                                  font-family: Poppins, sans-serif;
                                  line-height: 30px;
                                  color: white;
                                  letter-spacing: 0.5px;
                                "
                              >
                                Congratulations! You've earned a Free Ice Cream treat from
                                <span style="font-weight: 900">OK DADA</span>! We're
                                thrilled that you've taken part in our puzzle challenge.
                                Your sweet victory awaits!
                                <br />
                                <br />
                                Please click the link below to claim your coupon code and
                                enjoy a free ice cream in our shop!
                              </p>
                            </div>
                            <table
                              role="presentation"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                            >
                              <tr>
                                <td valign="top" width="50%">
                                  <table
                                    role="presentation"
                                    cellspacing="0"
                                    cellpadding="0"
                                    border="0"
                                    width="100%"
                                  >
                                    <tr>
                                      <td style="padding-top: 30px">
                                        <div
                                          class="text-project"
                                          style="text-align: center; margin-bottom: 40px"
                                        >
                                          <a
                                            href="https://ok-dada-puzzle.workplay.digital/redeem/${user.id}"
                                            style="
                                              text-decoration: none;
                                              line-height: 100%;
                                              background: #ff93d2;
                                              color: black;
                                              font-family: GeomanistRegular, sans-serif,
                                                sans-serif;
                                              font-size: 16px;
                                              font-weight: 600;
                                              text-transform: none;
                                              margin: 0px;
                                              padding: 18px 18px;
                                              border-radius: 5px;
                                              border: 2px solid black;
                                            "
                                            target="_blank"
                                          >
                                            Redeem Coupon
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
          
                  <table
                    align="center"
                    role="presentation"
                    cellspacing="0"
                    cellpadding="0"
                    border="0"
                    width="100%"
                    style="margin: auto"
                  >
                    <tr>
                      <td
                        valign="middle"
                        class="footer email-section"
                        style="
                          background: #7289da
                            url(https://s3.ap-south-1.amazonaws.com/cdn.workplay.digital/assets/blackbackground.png)
                            top center / cover no-repeat;
                          padding: 3em 2em;
                        "
                      >
                        <table>
                          <tr>
                            <td valign="top" width="33.333%">
                              <table
                                role="presentation"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                                width="100%"
                              >
                                <tr>
                                  <td
                                    style="
                                      text-align: left;
                                      padding-left: 5px;
                                      padding-right: 5px;
                                    "
                                  >
                                    <h3 class="heading" style="color: white">Address</h3>
          
                                    <span class="text" style="color: white"
                                      >399/A, 16th Cross Rd, Jaya Nagar 1st Block,
                                      Jayanagar 3rd Block, Jayanagar, Bengaluru, Karnataka
                                      560011</span
                                    >
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <!-- end: tr -->
                    <tr>
                      <td valign="middle" style="padding: 15px 0px">
                        <table>
                          <tr>
                            <td valign="top" width="33.333%">
                              <table
                                role="presentation"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                                width="100%"
                              >
                                <tr>
                                  <td style="text-align: center; padding-right: 10px">
                                    <p
                                      style="
                                        color: #000;
                                        margin: 0;
                                        font-size: 15px;
                                        font-weight: 400;
                                        margin-bottom: 5px;
                                      "
                                    >
                                      Sent by
                                    </p>
                                    <a href="https://workplay.digital/" target="_blank">
                                      <img
                                        style="height: 40px"
                                        src="https://s3.ap-south-1.amazonaws.com/cdn.workplay.digital/assets/workplay.png"
                                        alt="workplay logo"
                                      />
                                    </a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </center>
            </body>
          </html>                                     
          `;
          await sendRedemptionEmail(user.email, emailMessage);
        } else {
          throw new Error("Failed to save data");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Oops! Something went wrong!");
    } finally {
      toast.dismiss(loadingToast);
    }
  };

  const sendRedemptionEmail = async (email: string, message: string) => {
    try {
      await axios.post(
        "https://ok-dada-puzzle-api.workplay.digital/api/sendEmail",
        {
          mail: email,
          message: message,
          subject: "Congratulations! You won a coupon from OK DADA!",
        },
      );
      toast.success("Coupon code mailed, please check inbox");
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (error) {
      console.error("Error sending redemption email:", error);
      toast.error("Oops! Something went wrong!");
    }
  };

  return (
    <div className="fixed inset-0 z-40 flex min-h-full min-w-full flex-col items-center overflow-x-hidden overflow-y-hidden  transition  ">
      <div className=" z-10 flex h-screen w-screen bg-transparent backdrop-blur-sm"></div>
      <div className="PopShadow bg-opacity-65 absolute left-1/2 top-1/2 z-20 flex h-auto w-4/12 -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center gap-y-2 rounded-lg bg-[#fee1fa] p-4 max-sm:w-11/12 max-[400px]:py-0 max-[360px]:py-2">
        <section>
          <img
            className="mx-auto mt-4 flex h-[100px] justify-center max-sm:h-[80px]"
            src={OKDada}
            alt="ok dada"
          />
          <div className="flex flex-col items-center justify-center gap-y-2 ">
            {TimeOut ? (
              <h1 className="title font-semibold text-[#ff93d2] max-sm:text-[40px] max-[360px]:text-[35px]">
                Time out!!
              </h1>
            ) : (
              <h1 className="title font-semibold text-[#ff93d2] max-sm:text-[40px] max-[360px]:text-[35px]">
                Great Job!
              </h1>
            )}
            {TimeOut ? (
              <p className="description m-auto my-3 w-9/12 text-xl font-semibold text-black max-sm:my-1 max-sm:w-10/12 max-sm:text-lg max-[360px]:text-base">
                Sorry, you couldn't solve the puzzle in time.
              </p>
            ) : (
              <p className="description m-auto my-3 w-9/12 text-xl font-semibold text-black max-sm:my-1 max-sm:mb-2 max-sm:w-10/12 max-sm:text-lg max-[360px]:mb-3 max-[360px]:text-base">
                You've completed the Challenge in 0{minute}:{second % 60}s.
                Please fill this form to receive your coupon.
              </p>
            )}
            {!TimeOut && (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col items-start justify-start">
                  <input
                    type="text"
                    value={user.name}
                    className="mx-auto mb-2 h-14 w-[360px] rounded-xl border-2 border-black p-3 text-base text-black focus:outline-none max-sm:w-[300px] max-[360px]:h-12 max-[360px]:w-[250px] max-[360px]:text-sm"
                    placeholder="John doe"
                    name="name"
                    id="name"
                    onChange={(e) => {
                      setUser({
                        ...user,
                        name: e.target.value,
                      });
                    }}
                    required
                  />
                </div>
                <div className="flex flex-col items-start justify-start">
                  <input
                    type="email"
                    value={user.email}
                    className=" mx-auto mb-7 mt-1 h-14 w-[360px] rounded-xl border-2 border-black p-3 text-base text-black focus:outline-none max-sm:w-[300px] max-[360px]:h-12 max-[360px]:w-[250px] max-[360px]:text-sm"
                    placeholder="example@gmail.com"
                    name="email"
                    id="email"
                    onChange={(e) => {
                      setUser({
                        ...user,
                        email: e.target.value,
                      });
                    }}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="rounded-full border-2 border-black bg-[#ff93d2] px-12 py-3 font-geomanistRegular text-xl font-extrabold uppercase text-black shadow-btn transition-shadow hover:shadow-hoverBtn max-sm:text-lg max-[360px]:px-8 max-[360px]:py-2 max-[360px]:text-base"
                >
                  Submit
                </button>
              </form>
            )}

            {TimeOut && (
              <Link to={"/"}>
                <button
                  type="submit"
                  className="rounded-full border-2 border-black bg-[#ff93d2] px-12 py-2 font-geomanistRegular text-xl font-extrabold uppercase text-black shadow-btn transition-shadow hover:shadow-hoverBtn max-sm:mt-5 max-sm:text-lg max-[360px]:px-8 max-[360px]:py-2 max-[360px]:text-base"
                >
                  OK
                </button>
              </Link>
            )}

            <a
              href={REACT_APP_LOGO_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4 text-base text-black max-sm:text-sm"
            >
              powered by
              <img
                id="powered"
                src={powered}
                className="mb-4 h-10 max-sm:h-8"
                alt="poweredBy-workplay"
              />
            </a>
          </div>
        </section>
      </div>
      <div className=" text-base">
        <Toaster />
      </div>
    </div>
  );
}

export default Result;
