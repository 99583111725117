"use client";
// @ts-ignore

import { Canvas, painters, outline, generators } from "headbreaker";
import "../Styles/Puzzle.css";
import { useEffect, useRef, useState } from "react";
import puzzleCanvas from "../assets/puzzleimg.png";
import timer from "../assets/timer-grey.png";
import logo from "../assets/logo-ok-dada.png";
import gamePC from "../assets/gamepagePC.png";
import gameMobile from "../assets/gamepagemobile.png";
import { useMediaQuery } from "react-responsive";
import Result from "./Result";

interface Props {}

function Puzzle({}: Props) {
  const puzzleRef = useRef(null);
  const [seconds, setSeconds] = useState(() => {
    const savedTimerValue = sessionStorage.getItem("timerValue");
    return savedTimerValue ? parseInt(savedTimerValue, 10) + 1 : 1;
  });

  const isSmallScreen = useMediaQuery({ maxWidth: 500 });
  const isverySmallScreen = useMediaQuery({ maxWidth: 360 });
  const isMediumScreen = useMediaQuery({ maxWidth: 830 });
  let width: number, height: number, pieceSize: {}, x: number, y: number;

  if (isverySmallScreen) {
    document.body.style.backgroundImage = `url(${gameMobile})`;
    width = 320;
    height = 350;
    x = 80;
    y = 60;
  } else if (isSmallScreen) {
    document.body.style.backgroundImage = `url(${gameMobile})`;
    width = 320;
    height = 350;
    x = 100;
    y = 80;
  } else if (isMediumScreen) {
    document.body.style.backgroundImage = `url(${gamePC})`;
    width = 500;
    height = 400;
    x = 120;
    y = 90;
  } else {
    document.body.style.backgroundImage = `url(${gamePC})`;
    // Default values for larger screens
    width = 900;
    height = 500;
    x = 160;
    y = 120;
  }

  let puzzle_image = new Image();
  puzzle_image.src = puzzleCanvas;

  useEffect(() => {
    // Listen to the 'load' event to ensure the image is fully loaded.

    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundPosition = "center";

    puzzle_image.onload = () => {
      // @ts-ignore
      const canvas = new Canvas(puzzle.id, {
        image: puzzle_image,
        painter: new painters.Konva(),

        width: width,
        height: height,
        borderFill: 0,
        strokeWidth: 4,
        strokeColor: "#00200B",
        pieceSize: { x: x, y: y },

        lineSoftness: 0.7,
        outline: new outline.Rounded(),
        preventOffstageDrag: true,
        fixed: true,
      });

      canvas.adjustImagesToPuzzleWidth();
      canvas.autogenerate({
        horizontalPiecesCount: 3,
        verticalPiecesCount: 3,
        insertsGenerator: generators.random,
      });

      canvas.onConnect(
        (_piece: any, figure: any, _target: any, targetFigure: any) => {
          figure.shape.strokeWidth(0);
          targetFigure.shape.strokeWidth(0);
        },
      );

      canvas.onDisconnect(
        (_piece: any, figure: any, _target: any, targetFigure: any) => {
          figure.shape.strokeWidth(1.8);
          targetFigure.shape.strokeWidth(1.8);
        },
      );

      canvas.shuffle(0.9);
      canvas.draw();
      canvas.attachSolvedValidator();
      canvas.onValid(() => {
        setTimeout(() => {
          sessionStorage.setItem("gameCompleted", JSON.stringify(true));
        }, 800);
      });
    };
  }, []);

  useEffect(() => {
    // Start the timer when the component mounts
    const gameCompleted = sessionStorage.getItem("gameCompleted");

    if (!gameCompleted) {
      const timerInterval = setInterval(() => {
        if (seconds < 180) {
          sessionStorage.setItem("timerValue", JSON.stringify(seconds));
          setSeconds((prevSeconds) => prevSeconds + 1);
        }
      }, 1000);
      // Clean up the timer when the component unmounts
      return () => {
        clearInterval(timerInterval);
      };
    }

    // Start the timer
  }, [seconds]);

  let minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  useEffect(() => {
    if (seconds >= 179) {
      sessionStorage.setItem("gameCompleted", JSON.stringify(true));
    }
  }, [seconds]);

  return (
    <>
      <div className="flex h-screen w-screen flex-col items-center justify-center overflow-hidden p-4 sm:h-auto sm:p-10 md:p-12">
        <img
          src={logo}
          alt="OK Dada"
          className="mt-36 h-20 sm:mt-auto sm:h-24 md:h-28"
        />
        <div className="flex flex-col items-center justify-between md:flex-row md:items-start">
          <div className="flex flex-col items-center justify-center">
            <div className="mb-2 flex flex-row items-center justify-center">
              <img src={timer} className="mr-2 h-8  sm:h-10" alt="timer" />
              <h3 className="text-center  text-lg sm:text-xl">
                0{minutes} : {remainingSeconds < 10 ? "0" : ""}
                {remainingSeconds}
              </h3>
            </div>
            <div className="flex flex-col items-center justify-center ">
              <img
                src={puzzleCanvas}
                className=" h-36  sm:h-40 md:h-56"
                alt="OK DADA"
              />
              <div></div>
            </div>
          </div>
          <div
            ref={puzzleRef}
            id="puzzle"
            className="puzzle ml-0 sm:ml-20"
          ></div>
        </div>
      </div>
      {sessionStorage.getItem("gameCompleted") && (
        <Result
          seconds={seconds}
          minutes={minutes}
          remainingSeconds={remainingSeconds}
        />
      )}
    </>
  );
}

export default Puzzle;
