import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Confetti from "react-confetti";
import axios from "axios";
import okDadaLogo from "../assets/ok_dada_logo.png";
import poweredByWorkplay from "../assets/workplay.png";
import "../Styles/Redeem.css";

const { REACT_APP_LOGO_URL, REACT_APP_API } = process.env;

const Redeem = () => {
  const { uniqueId } = useParams();
  const [coupon, setCoupon] = useState("");

  useEffect(() => {
    const updateRedeemed = async () => {
      try {
        const response = await axios.put(
          `${REACT_APP_API}/update-redeemed/${uniqueId}`,
        );
        const { coupon } = response.data;
        // Update the coupon state with the redeemed coupon
        setCoupon(coupon);
      } catch (error) {
        console.error("Error redeeming coupon:", error);
      }
    };

    updateRedeemed();
  }, [uniqueId]);

  const handleLogoRedirect = () => {
    window.open(`${REACT_APP_LOGO_URL}`, "_blank");
  };

  return (
    <div id="redeem">
      <div>
        <Confetti width={window.innerWidth} height={window.innerHeight} />
        <img
          className="m-auto mb-10 h-32 text-center"
          src={okDadaLogo}
          alt="ok dada logo"
        />
        <div className="coupon-container h-auto w-[280px] rounded-md bg-[#fee1fa] bg-opacity-90 pb-1">
          <p className=" font-geomanist p-10 text-[22px] font-bold text-black">
            You've won a free Ice Cream!
          </p>
          <div className="mb-10 px-10">
            <div className="coupon flex h-14 w-full items-center justify-center rounded-md bg-[#ff93d2]">
              <p className="text-xl font-bold text-black">{coupon}</p>
            </div>
          </div>
        </div>
        <div>
          <p className="mt-12 text-base">powered by</p>
          <img
            className=" m-auto h-10 text-center "
            src={poweredByWorkplay}
            alt="powered by workplay"
            onClick={handleLogoRedirect}
          />
        </div>
      </div>
    </div>
  );
};

export default Redeem;
